<!--
 * 
 *邀请有奖
-->

<template>
  <div>
    <div id="inviteRecord" :class="!isApp ? 'appTop' : ''">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        :finished-text="list.length ? '-到底啦-' : ''"
        offset="200"
        class="recordList"
        @load="onLoad"
      >
        <div class="recordItem" v-for="item in list" :key="item.id">
          <div class="name">
            <div>{{ item.user_name }}</div>
            <!-- <div>ID：{{ item.receive_user_id }}</div> -->
          </div>

          <div class="success">
            <div>邀请成功</div>
            <div>{{ getTime(item.verify_time) }}</div>
          </div>
        </div>
      </van-list>

      <div v-if="!list.length" class="dataNullBox">
        <div>
          <img src="./../imgs/comm/dataNull.png" alt="" srcset="" />
        </div>
        <div class="text">暂无邀请记录</div>
      </div>

      <!-- 底部按钮 -->
      <div class="footer">
        <shareBtn v-if="activityStatus == 1" :activityId="activityId" :posterBg="posterBg" />
        <!-- <div class="posterBtn" v-if="diffTime(startTime) < 0" @click="qrcodeDom">
        生成专属邀请海报
      </div> -->
        <div v-if="activityStatus == 2" style="font-size: 16px" class="posterBtn disPosterBtn">
          本期活动已结束，敬请期待下期
        </div>

        <div
          v-if="activityStatus == 3"
          class="posterBtn"
          style="font-size: 16px"
          @click="toWinnerList"
        >
          本期活动已结束，查看获奖名单
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { getuserinvitationlog } from '@/service/invite';
import moment from 'moment';
import { nftUtils } from '@/utils';
import shareBtn from '@/components/inviteSharePoster/inviteSharePoster';
export default {
  name: 'inviteRecord',
  components: {
    shareBtn
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      list: [],
      finished: false,
      page: 1,
      loading: true,
      startTime: route.query.startTime || '',
      isApp: nftUtils.isInApp(),
      activityId: route.query.activityId,
      posterBg: require('../imgs/poster/posterBg0530.jpg'),
      activityStatus: route.query.activityStatus
    });

    const getList = () => {
      getuserinvitationlog({
        page: state.page,
        page_size: 10,
        act_id: state.activityId
      }).then(res => {
        console.log(res);
        if (res.status === 0) {
          state.pageLoading = false;
          state.list = state.list.concat(res.data || []);
          state.loading = false;
          if (!res.data || !res.data.length) {
            state.finished = true;
          }
        }
      });
    };

    const toWinnerList = () => {
      router.push({
        path: '/winnerList',
        query: { activityId: state.activityId }
      });
    };

    const onLoad = () => {
      if (!state.finished && state.list.length) {
        console.log(state.page);
        state.page = state.page + 1;
      }
      getList();
    };

    // 获取格式时间
    const getTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
      return moment(time).format(format);
    };

    onMounted(() => {
      // console.log(route.query.startTime);
      nftUtils.setcnzzRecordByBtn('进入邀请记录页面');
    });

    return {
      ...toRefs(state),
      onLoad,
      getTime,
      toWinnerList
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
* {
  touch-action: pan-y;
}

.appTop {
  padding-top: 70px;
}

.recordList {
  padding: 0 20px;
  .recordItem {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    margin-top: 20px;

    .name {
      :first-child {
        font-size: 14px;
      }

      :last-child {
        margin-top: 6px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    .success {
      text-align: right;

      :first-child {
        font-weight: 600;
      }

      :last-child {
        margin-top: 6px;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 134px;
  background: linear-gradient(180deg, rgba(28, 23, 42, 0) 0%, #000000 100%);
}

.dataNullBox {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 150px;
    height: 110px;
  }

  .text {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 400;
  }
}
</style>
<style lang="scss">
#inviteRecord {
  .vantPop {
    background: transparent !important;
  }
  .posterBtn {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 252px;
    height: 44px;
    background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
    border-radius: 4px 4px 4px 4px;
    font-size: 20px;
    font-weight: 500;
    color: #1c172a;
    line-height: 44px;
    text-align: center;
    opacity: 1;
  }
  .qrcodeImg {
    top: 352px;
  }
  .disPosterBtn {
    opacity: 0.6;
    font-size: 16px;
  }
}
</style>

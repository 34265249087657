<template>
  <div>
    <div class="posterBtn" @click="qrcodeDom">生成专属邀请海报</div>
    <!-- 不需要用开售时间去判断了 -->
    <!-- <div class="posterBtn" v-if="diffTime(startTime) > 0" @click="qrcodeDom">生成专属邀请海报</div> -->
    <!-- <div class="posterBtn disPosterBtn" v-if="diffTime(startTime) < 0">
      本期活动已结束，敬请期待下期
    </div> -->
    <!-- 海报 -->
    <div style="position: fixed; top: -200%; font-size: 0">
      <div id="testPost">
        <img class="posterBg" :src="posterBg" />
        <!-- <div id="qrcode" class="qrcodeImg"></div> -->
        <img class="qrcodeImg" :src="qrcodeImg" alt="" srcset="" />
      </div>
    </div>

    <van-popup class="vantPop" v-model:show="posterPop">
      <div class="poster">
        <!-- <van-image width="100" height="100" :src="posterImg" /> -->
        <img style="pointer-events: auto !important" :src="posterImg" alt="" srcset="" />

        <div v-if="posterImg" class="saveTip">长按图片保存</div>
      </div>
    </van-popup>

    <PageLoading :show="pageLoading" />
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import domtoimage from 'dom-to-image';
import QRCode from 'qrcode';
import upload from '@/utils/upload.js';
import { nftUtils } from '@/utils';
import PageLoading from '@/components/loading/PageLoading.vue';

export default {
  props: {
    startTime: {
      type: String,
      default: ''
    },
    btnStyle: {
      type: Object,
      default: () => {}
    },
    posterBg: {
      type: String,
      default: ''
    },
    activityId: {
      type: Number,
      default: 0
    }
  },

  components: {
    PageLoading
  },

  setup(props) {
    const store = useStore();
    const state = reactive({
      pageLoading: false,
      posterImg: '', // 海报图片
      isFlag: true, // 用于处理ios需要点击两次的问题 （后期改成http链接可能不需要）
      qrcodeImg: null, // 二维码图片
      posterPop: false // 弹窗显示
    });

    // 生成二维码
    const qrcodeDom = async () => {
      console.log('process.env.NODE_ENV', process.env.NODE_ENV);
      state.pageLoading = true;
      nftUtils.setcnzzRecordByBtn('生成专属邀请海报');
      let url = window.location;
      if (!window.location.origin) {
        url =
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '');
      } else {
        url = window.location.origin;
      }

      if (url.indexOf('mh51') > -1) {
        url = 'https://collect.yx03.com';
        // url = url.replace('https://collect.mh51.com/', 'https://collect.yx03.com/');
      }

      if (store.state.posterUrl) {
        // 上传过后不用生成和上传了
        havePosterUrlToPoster();
      } else {
        // 没有上传时重新上传
        await store.dispatch('refreshLoginInfo');
        QRCode.toDataURL(
          url +
            '/login?invite_user_id=' +
            (props.activityId == 1
              ? store.state.loginInfo.user_id
              : store.state.loginInfo.my_code) +
            '&activityId=' +
            props.activityId,
          { margin: 0 },
          (err, url) => {
            if (err) throw err;
            state.qrcodeImg = url;

            uploadDomToPoster(nftUtils.isIOS());
          }
        );
      }
    };
    // 直接转为Blob生成并上传的oss，iOS特殊处理，需要执行两次toBlob
    const uploadDomToPoster = isIos => {
      if (!store.state.posterUrl) {
        if (isIos) {
          domtoimage
            .toBlob(document.getElementById('testPost'), { quality: 1, width: 616, height: 1044 })
            .then(dataUrl => {
              updateDomToPosterOnece();
            });
        } else {
          updateDomToPosterOnece();
        }
      } else {
        havePosterUrlToPoster();
      }
    };

    const havePosterUrlToPoster = () => {
      if (nftUtils.isSupprotNftShare()) {
        nftUtils.nftSharePic(store.state.posterUrl);
        state.pageLoading = false;
      } else {
        state.posterImg = store.state.posterUrl;
        state.posterPop = true;
        state.pageLoading = false;
      }
    };

    // 直接转为toBlob，首次进入时两次生成并上传oss显示，第二次及以后，只需一次生成并上传oss显示
    const updateDomToPosterOnece = () => {
      domtoimage
        .toBlob(document.getElementById('testPost'), { quality: 1, width: 616, height: 1044 })
        .then(dataUrl => {
          if (nftUtils.isSupprotNftShare()) {
            upload.uploadHandle64(dataUrl, function (link) {
              nftUtils.nftSharePic(link);
              state.pageLoading = false;
              store.state.posterUrl = link;
            });
          } else {
            upload.uploadHandle64(dataUrl, function (link) {
              state.posterImg = link;
              state.posterPop = true;
              state.pageLoading = false;
              store.state.posterUrl = link;
            });
          }
        });
    };

    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    // 获取开售时间
    // const getList = async () => {
    //   await store.dispatch('getHomeList');
    //   state.startTime = store.state.homeList[0].start_time;
    // };

    onMounted(() => {
      // getList;
    });

    return {
      ...toRefs(state),
      qrcodeDom,
      diffTime
    };
  }
};
</script>

<style lang="scss" scoped>
// .posterBtn {
//   position: absolute;
//   bottom: 58px;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 252px;
//   height: 44px;
//   background: linear-gradient(132deg, #8ee4c8 0%, #30daec 100%);
//   border-radius: 4px 4px 4px 4px;
//   font-size: 20px;
//   font-weight: 500;
//   color: #1c172a;
//   line-height: 44px;
//   text-align: center;
//   opacity: 1;
// }

.disPosterBtn {
  opacity: 0.6;
  font-size: 16px;
}
#testPost {
  position: relative;
  display: inline-block;
  .posterBg {
    width: 616px;
    height: 1044px;
  }

  .qrcodeImg {
    width: 228px;
    height: 228px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    // top: 352px;
    border: 6px solid;
    border-radius: 2px 2px 2px 2px;
    border-image: linear-gradient(180deg, rgba(255, 233, 203, 1), rgba(141, 129, 118, 1)) 3 3;
  }
}

.poster {
  width: 308px;

  .saveTip {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #ffffff;
  }

  img {
    width: 100%;
  }
}
</style>
<style>
.vantPop {
  background: transparent !important;
}
</style>
